<template>
  <div>
    <Modal class="edit-inquiry" footer-hide v-model="showInquiry">
      <div class="top">
        <div class="name">Copy Product For Sourcing</div>
      </div>
      <div class="content">
        <div class="item">
          <div class="input">
            <div class="label label-bold">
              Product Name<span class="red-point">*</span>
            </div>
            <div class="value value-c">
              <input type="text" v-model="editInquiryForm.product_name" />
            </div>
          </div>
          <div class="input">
            <div class="label label-bold">
              Quantity<span class="red-point">*</span>
            </div>
            <div class="value ">
              <input type="text" v-model="editInquiryForm.quantity" />
            </div>
          </div>
        </div>
        <div class="item">
          <div class="input">
            <div class="label label-bold">
              Delivery Address<span class="red-point">*</span>
            </div>
            <div class="value">
              <input type="text" v-model="editInquiryForm.delivery_address" />
            </div>
          </div>
          <div class="input">
            <div class="label label-bold">
              Basis<span class="red-point">*</span>
            </div>
            <div class="value">
              <input
                type="text"
                v-model="editInquiryForm.basis"
                placeholder="FOB/CFR/DDP Or Other"
              />
            </div>
          </div>

          <div class="input">
            <div class="label">
              Specification
            </div>
            <div class="value">
              <input type="text" v-model="editInquiryForm.quality_standard" />
            </div>
          </div>
        </div>

        <div class="item">
          <div class="input">
            <div class="label">
              Delivery Date
            </div>
            <div class="value ">
              <input
                type="date"
                placeholder="Select a date"
                v-model="editInquiryForm.delivery_date"
              />
            </div>
          </div>
          <div class="input">
            <div class="label">
              Packaging
            </div>
            <div class="value">
              <input type="text" v-model="editInquiryForm.packaging" />
            </div>
          </div>
          <div class="input">
            <div class="label">
              Required Documents
            </div>
            <div class="value">
              <input type="text" v-model="editInquiryForm.required_documents" />
            </div>
          </div>
        </div>
        <div class="item">
          <div class="input">
            <div class="label">
              Sourcing Expire Date(PST)
            </div>
            <div class="value ">
              <input
                type="date"
                placeholder="Select a date"
                v-model="editInquiryForm.expire_date"
              />
            </div>
          </div>
          <div class="input">
            <div class="label">
              Application Industry
            </div>
            <div class="value">
              <input type="text" v-model="editInquiryForm.application" />
            </div>
          </div>
          <div class="input">
            <div class="label"></div>
            <div class="value value-hide">
              <div
                class="value-item search-button"
                @click="changeSampleRequest"
              >
                <div class="item-icon">
                  <div class="icon" v-if="editInquiryForm.sample_request !== 1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_11_80)">
                        <rect
                          x="4"
                          y="4"
                          width="16"
                          height="16"
                          rx="4"
                          fill="#F9F9F9"
                        />
                        <rect
                          x="5"
                          y="5"
                          width="14"
                          height="14"
                          rx="3"
                          stroke="#000"
                          stroke-width="2"
                        />
                        <path
                          d="M-3 11.9375L-0.565217 15L5 8"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_11_80">
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="4"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div class="icon" v-if="editInquiryForm.sample_request === 1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="4"
                        y="4"
                        width="16"
                        height="16"
                        rx="4"
                        fill="#F9F9F9"
                      />
                      <rect
                        x="5"
                        y="5"
                        width="14"
                        height="14"
                        rx="3"
                        stroke="#F57F45"
                        stroke-width="2"
                      />
                      <path
                        d="M8 11.9375L10.4348 15L16 8"
                        stroke="#F57F45"
                        stroke-width="2"
                      />
                      <rect
                        x="4"
                        y="4"
                        width="16"
                        height="16"
                        rx="4"
                        stroke="#F57F45"
                      />
                    </svg>
                  </div>
                </div>
                <div class="item-word">Sample Request</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="input">
            <div class="label">
              Estimated Annual Quantity
            </div>
            <div class="value">
              <input
                type="text"
                v-model="editInquiryForm.estimated_annual_quantity"
              />
            </div>
          </div>
          <div class="input">
            <div class="label">
              Type
            </div>
            <div class="value">
              <select v-model="editInquiryForm.sourcing_type">
                <option value="">- Select Type -</option>
                <option value="Spot Purchasing">Spot Purchasing</option>
                <option value="Blanket Order">Blanket Order</option>
                <option value="Annual Bid">Annual Bid</option>
                <option value="RFQ">RFQ</option>
                <option value="R&D">R&D</option>
                <option value="Quotation">Quotation</option>
                <option value="Market Research">Market Research</option>
              </select>
            </div>
          </div>
          <div class="input">
            <div class="label"></div>
            <div class="value value-hide">
              <div class="value-item search-button" @click="changeHideCompany">
                <div class="item-icon">
                  <div class="icon" v-if="editInquiryForm.hidden_company !== 1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_11_80)">
                        <rect
                          x="4"
                          y="4"
                          width="16"
                          height="16"
                          rx="4"
                          fill="#F9F9F9"
                        />
                        <rect
                          x="5"
                          y="5"
                          width="14"
                          height="14"
                          rx="3"
                          stroke="#000"
                          stroke-width="2"
                        />
                        <path
                          d="M-3 11.9375L-0.565217 15L5 8"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_11_80">
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="4"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div class="icon" v-if="editInquiryForm.hidden_company === 1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="4"
                        y="4"
                        width="16"
                        height="16"
                        rx="4"
                        fill="#F9F9F9"
                      />
                      <rect
                        x="5"
                        y="5"
                        width="14"
                        height="14"
                        rx="3"
                        stroke="#F57F45"
                        stroke-width="2"
                      />
                      <path
                        d="M8 11.9375L10.4348 15L16 8"
                        stroke="#F57F45"
                        stroke-width="2"
                      />
                      <rect
                        x="4"
                        y="4"
                        width="16"
                        height="16"
                        rx="4"
                        stroke="#F57F45"
                      />
                    </svg>
                  </div>
                </div>
                <div class="item-word">Hide company name</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="input">
            <div class="label">
              Note
            </div>
            <div class="value value-c">
              <input type="text" v-model="editInquiryForm.note" />
            </div>
          </div>

          <div class="input">
            <div class="label label-bold">
              Delivery Country<span class="red-point">*</span>
            </div>
            <div class="value">
              <select v-model="editInquiryForm.country">
                <option value="">- Select Country -</option>
                <option
                  v-for="item in countryList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</option
                >
              </select>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="input">
            <div class="label">Other Names or Keywords</div>
            <div class="value value-c">
              <input type="text" v-model="editInquiryForm.other_names" />
            </div>
          </div>
        </div>
      </div>

      <div class="btn" v-if="!isView">
        <div class="save-btn search-button" v-if="!btnLoading" @click="save">
          Save
        </div>
        <div class="save-btn" v-else>Saving</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";

const { _createSourcing, _country } = api;
import { mapState } from "vuex";

export default {
  props: ["isEditInquiry"],
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      editInquiryForm: {
        id: null,
        product_name: null,
        quality_standard: null,
        quantity: null,
        packaging: null,
        in_stock: 0,
        required_documents: null,
        expire_date: null,
        basis_and_price: "",
        type: 1,
        basis: "",
        hidden_company: 0,
        application: null,
        sample_request: 0,
        other_names: ""
      },
      showInquiry: false,
      loading: false,
      inStock: true,
      btnLoading: false,
      basisAndPriceVal: [["FOB", null]],
      basisVal: ["FOB", "CIF"],
      isEdit: false,
      isView: false,
      countryList: [
        { id: 1, name: "United States" },
        { id: 2, name: "Canada" },
        { id: 45, name: "China" }
      ]
      // inquriy: {}
    };
  },
  mounted() {
    _country().then(response => {
      const { data } = response;
      this.countryList = data;
    });
  },
  methods: {
    changeSampleRequest() {
      this.editInquiryForm.sample_request =
        this.editInquiryForm.sample_request === 0 ? 1 : 0;
    },
    changeBasis(basis) {
      this.editInquiryForm.basis === basis
        ? (this.editInquiryForm.basis = "")
        : (this.editInquiryForm.basis = basis);
    },
    changeHideCompany() {
      this.editInquiryForm.hidden_company =
        this.editInquiryForm.hidden_company === 0 ? 1 : 0;
    },
    bulkUpload() {
      this.$emit("inquiryBulkUpload");
    },
    minusBasis(id) {
      this.$delete(this.editInquiryForm.basis_and_price, id);
    },
    changeStock() {
      this.editInquiryForm.in_stock = this.editInquiryForm.in_stock > 0 ? 0 : 1;
    },
    addBasis() {
      this.editInquiryForm.basis_and_price.push(["FOB", null]);
    },

    save() {
      this.btnLoading = true;
      if (this.role === "") {
        this.btnLoading = false;
        this.$Message.error("Please log in first");
        return;
      }
      // this.editInquiryForm.in_stock = this.inStock ? 1 : 0;
      // this.editInquiryForm.basis_and_price = this.basisAndPriceVal;
      _createSourcing({ ...this.editInquiryForm })
        .then(() => {
          this.$Message.success("success");
          this.showInquiry = false;
          this.editInquiryForm = {};
          this.$emit("getSourcings");
        })
        .catch(err => {
          this.$Message.error(err.message);
        })
        .finally(() => {
          this.btnLoading = false;
        });
    }
  }
};
</script>
<style lang="less" scoped>
.edit-inquiry {
  .top {
    margin-bottom: 30px;

    .name {
      color: #23262f;
      font-size: 24px;
      font-weight: 600;
      .bulk {
        font-size: 14px;
        color: #7f8083;
        span {
          cursor: pointer;
          color: #f57f45;
        }
      }
    }
  }

  .content {
    .item {
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;

      .input {
        .label {
          color: #87898e;
          font-size: 16px;
          margin-bottom: 12px;

          .red-point {
            color: red;
            position: relative;
            top: 4px;
            left: 3px;
            font-size: 18px;
          }
        }
        .label-bold {
          font-weight: 800;
          color: #000;
        }
        .plus {
          width: 20px;
          position: relative;
          top: 3px;
          left: 2px;
        }

        .minus {
          width: 20px;
          position: relative;
          top: 3px;
          left: 2px;
        }

        .value {
          width: 240px;
          .value-item {
            display: flex;
            /*width: 78px;*/
            margin-top: 8px;
          }
          input {
            border-radius: 12px;
            height: 42px;
            border: 2px solid #dfdfe6;
            outline: none;
            width: 240px;
            padding-left: 10px;
          }
          select {
            border-radius: 12px;
            height: 42px;
            border: 2px solid #dfdfe6;
            outline: none;
            width: 240px;
            padding-left: 10px;
          }
          .basis-price {
            width: 145px !important;
          }

          input:focus {
            border: 2px solid #f57f45;
          }

          .basis {
            display: flex;

            .select {
              width: 70px;

              select {
                height: 42px;
                border: 2px solid #dfdfe6;
                border-right: none;
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                width: 70px;
                outline: none;
              }
            }

            .basic-value {
              input {
                width: 170px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
              }
            }
          }
        }

        .value-type {
          display: flex;
          padding-top: 8px;
          .value-item {
            display: flex;
            width: 78px;
          }
        }
        .value-hide {
          margin-top: 40px;
        }
        .basis-value {
          padding-bottom: 10px;
        }

        .value-c {
          input {
            width: 488px;
          }
        }

        .value-stock {
          padding-top: 4px;
        }
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 10px;

    .save-btn {
      background: #f57f45;
      border-radius: 50px;
      width: 450px;
      height: 45px;
      color: #fcfcfd;
      font-weight: 600;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<style lang="less">
.edit-inquiry {
  .ivu-modal {
    width: 820px !important;

    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
