<template>
  <div>
    <Modal class="pop-upload" footer-hide v-model="showUpload">
      <div class="header">
        <div class="word">
          My Complete List
          <!--          <div class="new search-button">-->
          <!--            Add-->
          <!--            <span>-->
          <!--              <svg width="22" height="22" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--<path d="M21.25 18.75V10H18.75V18.75H10V21.25H18.75V30H21.25V21.25H30V18.75H21.25Z" fill="#F57F45"/>-->
          <!--</svg>-->
          <!--</span>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="content">
        <template v-if="imageList.length > 0">
          <div class="title">
            <div class="name">Name</div>
            <div class="file">File</div>
            <div class="date">Date</div>
            <div class="delete">Action</div>
          </div>
          <div class="main">
            <div class="item" v-for="item in imageList" :key="item.id">
              <div class="item-name">{{ item.name || "--" }}</div>
              <div class="item-url">
                <a :href="item.url" target="_blank">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.3756 27.5898H21.4811V18.125C21.4811 17.9531 21.3405 17.8125 21.1686 17.8125H18.8249C18.653 17.8125 18.5124 17.9531 18.5124 18.125V27.5898H15.6256C15.3639 27.5898 15.2194 27.8906 15.3795 28.0938L19.7545 33.6289C19.7838 33.6663 19.8211 33.6965 19.8638 33.7172C19.9064 33.738 19.9532 33.7488 20.0006 33.7488C20.0481 33.7488 20.0949 33.738 20.1375 33.7172C20.1801 33.6965 20.2175 33.6663 20.2467 33.6289L24.6217 28.0938C24.7819 27.8906 24.6373 27.5898 24.3756 27.5898V27.5898Z"
                      fill="#F57F45"
                    />
                    <path
                      d="M31.6953 14.3242C29.9063 9.60547 25.3477 6.25 20.0078 6.25C14.668 6.25 10.1094 9.60156 8.32031 14.3203C4.97266 15.1992 2.5 18.25 2.5 21.875C2.5 26.1914 5.99609 29.6875 10.3086 29.6875H11.875C12.0469 29.6875 12.1875 29.5469 12.1875 29.375V27.0312C12.1875 26.8594 12.0469 26.7188 11.875 26.7188H10.3086C8.99219 26.7188 7.75391 26.1953 6.83203 25.2461C5.91406 24.3008 5.42578 23.0273 5.46875 21.707C5.50391 20.6758 5.85547 19.707 6.49219 18.8906C7.14453 18.0586 8.05859 17.4531 9.07422 17.1836L10.5547 16.7969L11.0977 15.3672C11.4336 14.4766 11.9023 13.6445 12.4922 12.8906C13.0745 12.1434 13.7643 11.4865 14.5391 10.9414C16.1445 9.8125 18.0352 9.21484 20.0078 9.21484C21.9805 9.21484 23.8711 9.8125 25.4766 10.9414C26.2539 11.4883 26.9414 12.1445 27.5234 12.8906C28.1133 13.6445 28.582 14.4805 28.918 15.3672L29.457 16.793L30.9336 17.1836C33.0508 17.7539 34.5312 19.6797 34.5312 21.875C34.5312 23.168 34.0273 24.3867 33.1133 25.3008C32.665 25.7517 32.1318 26.1092 31.5444 26.3526C30.957 26.596 30.3272 26.7204 29.6914 26.7188H28.125C27.9531 26.7188 27.8125 26.8594 27.8125 27.0312V29.375C27.8125 29.5469 27.9531 29.6875 28.125 29.6875H29.6914C34.0039 29.6875 37.5 26.1914 37.5 21.875C37.5 18.2539 35.0352 15.207 31.6953 14.3242Z"
                      fill="#F57F45"
                    />
                  </svg>
                </a>
              </div>
              <div class="item-date">
                {{ item.created_at | moment }}
              </div>
              <div
                class="item-delete search-button"
                @click="deleteImage(item.id)"
              >
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.875 8.125V8.75H23.125V8.125C23.125 7.2962 22.7958 6.50134 22.2097 5.91529C21.6237 5.32924 20.8288 5 20 5C19.1712 5 18.3763 5.32924 17.7903 5.91529C17.2042 6.50134 16.875 7.2962 16.875 8.125ZM14.375 8.75V8.125C14.375 6.63316 14.9676 5.20242 16.0225 4.14752C17.0774 3.09263 18.5082 2.5 20 2.5C21.4918 2.5 22.9226 3.09263 23.9775 4.14752C25.0324 5.20242 25.625 6.63316 25.625 8.125V8.75H35C35.3315 8.75 35.6495 8.8817 35.8839 9.11612C36.1183 9.35054 36.25 9.66848 36.25 10C36.25 10.3315 36.1183 10.6495 35.8839 10.8839C35.6495 11.1183 35.3315 11.25 35 11.25H33.115L30.75 31.96C30.5756 33.4852 29.846 34.8929 28.7002 35.9147C27.5544 36.9364 26.0727 37.5007 24.5375 37.5H15.4625C13.9273 37.5007 12.4456 36.9364 11.2998 35.9147C10.154 34.8929 9.4244 33.4852 9.25 31.96L6.885 11.25H5C4.66848 11.25 4.35054 11.1183 4.11612 10.8839C3.8817 10.6495 3.75 10.3315 3.75 10C3.75 9.66848 3.8817 9.35054 4.11612 9.11612C4.35054 8.8817 4.66848 8.75 5 8.75H14.375ZM11.735 31.675C11.8394 32.5899 12.2767 33.4344 12.9637 34.0476C13.6506 34.6608 14.5392 34.9998 15.46 35H24.5388C25.4596 34.9998 26.3481 34.6608 27.0351 34.0476C27.7221 33.4344 28.1594 32.5899 28.2638 31.675L30.6 11.25H9.40125L11.735 31.675ZM16.25 15.625C16.5815 15.625 16.8995 15.7567 17.1339 15.9911C17.3683 16.2255 17.5 16.5435 17.5 16.875V29.375C17.5 29.7065 17.3683 30.0245 17.1339 30.2589C16.8995 30.4933 16.5815 30.625 16.25 30.625C15.9185 30.625 15.6005 30.4933 15.3661 30.2589C15.1317 30.0245 15 29.7065 15 29.375V16.875C15 16.5435 15.1317 16.2255 15.3661 15.9911C15.6005 15.7567 15.9185 15.625 16.25 15.625V15.625ZM25 16.875C25 16.5435 24.8683 16.2255 24.6339 15.9911C24.3995 15.7567 24.0815 15.625 23.75 15.625C23.4185 15.625 23.1005 15.7567 22.8661 15.9911C22.6317 16.2255 22.5 16.5435 22.5 16.875V29.375C22.5 29.7065 22.6317 30.0245 22.8661 30.2589C23.1005 30.4933 23.4185 30.625 23.75 30.625C24.0815 30.625 24.3995 30.4933 24.6339 30.2589C24.8683 30.0245 25 29.7065 25 29.375V16.875Z"
                    fill="#F57F45"
                  />
                </svg>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="no-data">No Data</div>
        </template>
      </div>
      <div class="page" v-if="imageList.length > 0">
        <Page
          transfer
          :page-size="imageLimit"
          :total="imageTotal"
          show-elevator
          show-total
          @on-change="pageChange"
          @on-page-size-change="pageSizeChange"
        />
      </div>

      <div class="btn">
        <div class="cancel search-button" @click="cancel">Cancel</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";
const { _queryInquiryImages, _deleteInquiryImages } = api;
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: [],
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      inquiryForm: { price: "", note: "", id: "" },
      showUpload: false,
      loading: false,
      showInfo: false,
      infoParam: { company_name: "", user: "", email: "" },
      btnLoading: false,
      inquiry: this.inquiry,
      inquriy: {},
      showUploading: false,
      uploading: false,
      url: "",
      file: null,
      uplodLoading: false,
      imageList: [],
      imagePage: 1,
      imageTotal: 0,
      imageLimit: 5,
      isCurrentUser: true,
      companyId: 0,
      companyName: ""
    };
  },
  mounted() {},
  watch: {
    companyId(newData) {
      this.companyId = newData;
      this.getImages();
    }
  },
  filters: {
    moment(val) {
      return moment
        .utc(val)
        .local()
        .fromNow();
    }
  },
  methods: {
    deleteImage(id) {
      this.$Modal.confirm({
        loading: true,
        title: "",
        content: "Are you sure to delete this item?",
        onOk: () => {
          _deleteInquiryImages({
            id: id
          })
            .then(() => {
              this.$Message.success("success");
              this.getImages();
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.$Modal.remove();
            });
        }
      });
    },
    pageChange(page) {
      this.imagePage = page;
      this.getImages();
    },

    pageSizeChange(limit) {
      this.imagePage = limit;
      this.getImages();
    },
    getImages() {
      _queryInquiryImages({ page: this.imagePage, id: this.companyId }).then(
        response => {
          const { data } = response;
          this.imageList = data.data;
          this.imageTotal = data.total;
        }
      );
    },
    cancel() {
      this.showUpload = false;
    }
  }
};
</script>
<style lang="less" scoped>
.pop-upload {
  padding: 30px;
  .header {
    display: flex;
    .word {
      display: flex;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
      .new {
        width: 70px;
        height: 25px;
        color: #f57f45;
        border: 1px solid #f57f45;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 3px 0 0 10px;
        border-radius: 5px;
        font-size: 15px;
        span {
          margin-top: 6px;
        }
      }
    }
    .icon {
      margin: 0 0 5px 10px;
    }
  }
  .content {
    .title {
      display: flex;
      background: #f2f2f2;
      height: 35px;
      .name {
        width: 200px;
        text-align: CENTER;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .file {
        text-align: CENTER;
        display: flex;
        align-items: center;
        width: 100px;
        justify-content: center;
      }
      .date {
        text-align: CENTER;
        display: flex;
        align-items: center;
        width: 120px;
        justify-content: center;
      }
      .delete {
        text-align: CENTER;
        display: flex;
        align-items: center;
        width: 100px;
        justify-content: center;
      }
    }
  }
  .content .main {
    /*display: flex;*/
    justify-content: left;
    .item {
      /*border: 1px solid #c9c8c8;*/
      /*padding: 7px;*/
      display: flex;
      margin-right: 25px;
      border-bottom: 1px solid #e3e3e3;
      img {
        width: 150px;
        height: 100px;
      }
      .del {
        height: 0px;
        position: relative;
        bottom: 108px;
        left: 117px;
      }
      .item-name {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .item-url {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
      }
      .item-date {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
      }
      .item-delete {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
      }
    }
  }
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  .upload-div {
    display: flex;
    justify-content: left;
    border-bottom: 1px solid #c9c8c8;
    margin-bottom: 20px;
    .upload-btn {
      background: #f57f45;
      color: #fff;
      /* padding: 3px 5px; */
      margin: 50px 0 0 30px;
      width: 85px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .uploading-btn {
      background: #f57f45;
    }
    .img {
      img {
        width: 150px;
        height: 100px;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: right;
    color: #8f8f8f;
    margin-top: 20px;
  }
}
.page {
  margin-top: 20px;
  text-align: center;
}
</style>

<style lang="less">
.pop-upload {
  .ivu-modal {
    width: 620px !important;
    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
